/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
require('../css/app.css');
require('bootstrap/dist/css/bootstrap.css');
import "../scss/base.scss"
require('webpack-jquery-ui/css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
const $ = require('jquery');

global.$ = global.jquery = global.jQuery = jquery = jQuery = $;
require('popper.js');
require('bootstrap');
require('webpack-jquery-ui');

console.log('Hello Webpack Encore! Edit me in assets/app.js');
